<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden" style="height: calc(100vh - 48px)">
      <Loading />
      <NavBar tittle="label.lbl_additionalInformationByFormat" />
      <v-container fill-height style="display: flex; justify-content: center">
        <v-form ref="form" lazy-validation style="width: 60%" @submit.prevent>
          <!-- 入力項目 -->
          <v-container fluid>
            <!--  路線業者名 -->
            <v-row>
              <v-col class="detail-top-element" cols="3">
                <span>{{ $t("label.lbl_routeOperator") }}</span></v-col
              >
              <v-col class="detail-rightTop-element">{{ routeOperator }}</v-col>
            </v-row>
            <!-- フォーマットNo -->
            <v-row>
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_formatNo") }}</span></v-col
              >
              <v-col class="detail-right-element">{{ formatNo }}</v-col>
            </v-row>
            <!-- 出荷予定No -->
            <v-row>
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_expectedShippingDocument") }}</span></v-col
              >
              <v-col class="detail-right-element">{{ expectedShippingDocument }}</v-col>
            </v-row>
            <!-- 品番 -->
            <v-row>
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_productCode") }}</span></v-col
              >
              <v-col class="detail-right-element">{{ productCode }}</v-col>
            </v-row>
            <!-- 数量 -->
            <v-row>
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_quantity") }}</span></v-col
              >
              <v-col class="detail-right-element">{{ quantity }}</v-col>
            </v-row>
            <!-- お届け先 -->
            <v-row>
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_addressee") }}</span></v-col
              >
              <v-col class="detail-right-element">{{ addressee }}</v-col>
            </v-row>
            <!-- お届け希望日 -->
            <!-- <v-row v-if="routeOperatorIdentifier === 'SA'">
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_dateToDeliver") }}</span></v-col
              >
              <v-col class="detail-right-element">
                <div style="float: left">
                  <v-text-field
                    dense
                    outlined
                    v-model="dateToDeliver"
                    maxLength="10"
                    class="text-box"
                    full-width
                    :rules="[rules.yyyymmdd]"
                    @change="changeDate"
                  />
                </div>
                <div style="float: left" class="calender-component">
                  <v-menu
                    v-model="dateMenuTo"
                    :close-on-content-click="false"
                    :nudge-right="-100"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="dateToDeliverCal"
                      @input="dateMenuTo = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row> -->
            <!-- お届け希望時間帯 -->
            <!-- <v-row v-if="routeOperatorIdentifier === 'SA'">
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_desiredDeliveryTime") }}</span>
              </v-col>
              <v-col class="detail-right-element">
                <v-radio-group row v-model="desiredDeliveryTime">
                  <v-radio :label="$t('label.lbl_unspecified')"></v-radio>
                  <v-radio :label="$t('label.lbl_2pm')"></v-radio>
                  <v-radio :label="$t('label.lbl_4pm')"></v-radio>
                  <v-radio :label="$t('label.lbl_6pm')"></v-radio>
                  <v-radio :label="$t('label.lbl_7pm')"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <!受け取りサービス選択 -->
            <!-- <v-row v-if="routeOperatorIdentifier === 'YT'">
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_pick-upServiceSelection") }}</span>
              </v-col>
              <v-col class="detail-right-element">
                <v-checkbox
                  v-model="isPickupServiceSelection"
                  :label="$t('label.lbl_pick-upService')"
                  color="primary"
                  hide-details
                  class="inner-checkBox"
                ></v-checkbox>
              </v-col>
            </v-row> -->
            <!-- 冷凍・冷蔵選択 -->
            <v-row v-if="controllView('frozenRefrigeratedSelection')">
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_frozenRefrigeratedSelection") }}</span>
              </v-col>
              <v-col class="detail-right-element">
                <!-- <div
                  v-if="routeOperatorIdentifier === 'SA'"
                  style="float: left; height: 150%; margin-right: 14px"
                > -->
                <!-- クール -->
                <!-- <v-checkbox
                    v-model="isCoolSelection"
                    :label="$t('label.lbl_cool')"
                    color="primary"
                    hide-details
                    @change="checkFrozenRefrigeratedSelection('cool')"
                  ></v-checkbox>
                </div> -->
                <!-- 冷凍 -->
                <div style="float: left; height: 150%">
                  <v-checkbox
                    v-model="viewData.isFrozenSelected"
                    :label="$t('label.lbl_frozen')"
                    color="primary"
                    hide-details
                    @change="checkFrozenRefrigeratedSelection('frozen')"
                  ></v-checkbox>
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!-- 冷蔵 -->
                <div style="float: left; height: 150%">
                  <v-checkbox
                    v-model="viewData.isRefrigeratedSelection"
                    :label="$t('label.lbl_refrigerated')"
                    color="primary"
                    hide-details
                    @change="checkFrozenRefrigeratedSelection('refrigerated')"
                  ></v-checkbox>
                </div>
              </v-col>
            </v-row>
            <!-- 保険金額 -->
            <v-row>
              <v-col class="detail" cols="3">
                <span>{{ $t("label.lbl_insuranceAmount") }}</span>
              </v-col>
              <v-col class="detail-right-element">
                <v-text-field
                  dense
                  outlined
                  v-model="viewData.insuranceAmount"
                  class="input-number shrink"
                  :rules="[rules.isNumber, rules.maxLength8]"
                  @change="
                    viewData.insuranceAmount = parseAmountFormat(viewData.insuranceAmount, true)
                  "
                  maxLength="8"
                />
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <!--ボタン領域-->
              <div class="search-row-exeBtn pt-2">
                <!-- 請求内容 -->
                <div style="float: left">
                  <!-- 戻るボタン-->
                  <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">
                    {{ $t("btn.btn_back") }}
                  </v-btn>
                </div>
                <div style="float: right">
                  <!-- 行追加ボタン-->
                  <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_insert')">
                    {{ $t("btn.btn_insert") }}
                  </v-btn>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :shpAddFormatFlg="infoDialog.shpAddFormatFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
export default {
  name: appConfig.SCREEN_ID.P_SHP_010,
  components: {
    Loading,
    NavBar,
    ConfirmDialog,
    SimpleDialog,
  },
  data: () => ({
    inputList: [],
    inputListOld: {},
    inputListNew: {},
    loadingCounter: 0,
    // 路線業者
    routeOperator: "",
    // フォーマットNo
    formatNo: "",
    // 出荷予定伝票
    expectedShippingDocument: "",
    // 品番
    productCode: "",
    // 保険金額
    insuranceAmount: "",
    // 数量
    quantity: "",
    // お届け先
    addressee: "",
    // 受け取りサービス選択
    isPickupServiceSelection: false,
    // クール
    isCoolSelection: false,
    // 冷凍
    isFrozenSelected: false,
    // 冷蔵
    isRefrigeratedSelection: false,
    // お届け希望日
    dateToDeliver: "",
    // お届け希望日(カレンダー)
    dateToDeliverCal: "",
    // お届け希望時間帯
    desiredDeliveryTime: "",
    routeOperatorIdentifier: "",
    dateMenuTo: false,
    routeSlipSearchConditions: {},
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      shpAddFormatFlg: false,
    },
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    viewData: {},
    tmpViewData: {},
    backMessage: "",
    updateDialogMessage: false,
    viewItemMap: new Map([
      ["SA001", ["frozenRefrigeratedSelection"]],
      ["SA002", []],
    ]),
    rules: {
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      yyyymmdd: function (value) {
        if (!value) {
          return true;
        }
        if (
          !(
            dateTimeHelper.validDate(commonUtil.zen2han(value), "/") && dateTimeHelper.isDate(value)
          )
        ) {
          return i18n.tc("check.chk_inputDate");
        }
        return true;
      },
      maxLength8: function (value) {
        if (!value) {
          return true;
        }
        if (8 < commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", "").length) {
          return i18n.tc("check.chk_limitLength_8");
        }
        return true;
      },
    },
  }),

  methods: {
    init() {
      this.routeSlipSearchConditions = this.$route.params.searchConditions;
      this.routeOperator = this.routeSlipSearchConditions.truckNm;
      this.formatNo = this.routeSlipSearchConditions.invoiceTemplateSelected;
      this.addressee = this.routeSlipSearchConditions.address;
      this.expectedShippingDocument = this.$route.params.shippingNo;
      this.searchDeliveryTraderSid = this.routeSlipSearchConditions.searchDeliveryTraderSid;
      this.outListSid = this.routeSlipSearchConditions.outListSid;
      this.carrierCompCd7 = this.routeSlipSearchConditions.carrierCompCd7;
      let dtlList = this.routeSlipSearchConditions.dtlList;
      if (dtlList != null) {
        for (let i = 0; i < dtlList.length; i++) {
          let item = {
            productCode: dtlList[i].itemCd,
            quantity: dtlList[i].shipScheduleQuantity,
          };
          this.inputList.push(item);
        }
      } else {
        this.loadingCounter = 0;
      }
      for (let j = 0; j < this.inputList.length; j++) {
        let productCode = this.inputList[j].productCode;
        this.productCode = j == 0 ? productCode : this.productCode + ", " + productCode;
        this.quantity = Number(this.quantity) + Number(this.inputList[j].quantity);
      }
      this.getFormat();
    },

    /**
     * 更新されているか判定する
     */
    isDataUpdated() {
      let updateObj = JSON.stringify(this.viewData);
      let tmpObj = JSON.stringify(this.tmpViewData);
      if (updateObj === tmpObj) {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-SHP-010_001_E");
        return false;
      }
      return true;
    },

    /**
     * 初期表示API
     */
    getFormat() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createGetApiRequestConfig();

      body.params.outListSid = this.outListSid; //出荷伝票SID
      body.params.formatNo = this.formatNo; //フォーマットNo
      body.params.deliveryTraderSid = this.searchDeliveryTraderSid; //路線業者SID

      // console.debug("getFormat() Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.ROUTE_SLIP_FORMAT, body)
          .then((response) => {
            // console.debug("getFormat() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 成功
            if (jsonData.resCom.resComCode == "000") {
              // 返却されたurlをデータに格納、ダウンロードボタンの活性化
              const list = [];
              jsonData.resIdv.formatMatch.forEach((row) => {
                list.push({
                  // 出荷予定付属Sid
                  outScheduleSubSid: row.outScheduleSubSid,
                  // 路線伝票Sid
                  outListSid:
                    row.outListSid == null || row.outListSid == ""
                      ? this.outListSid
                      : row.outListSid,
                  // 付属情報
                  subInfomationCd: row.subInfomationCd,
                  // 配達情報
                  subInfomation: row.subInfomation,
                  // お届け先希望日
                  subInfomationComment: row.subInfomationComment,
                  // 路線伝票Sid
                  deliveryTraderSid: row.deliveryTraderSid,
                  // 登録日
                  updateDatetime: row.updateDatetime,
                  // コード
                  code: row.code,
                  // フォーマットNo
                  formatNo: row.formatNo,
                  // ファイルコード
                  fieldCode: row.fieldCode,
                  // 輸送会社CD
                  carrierCompCd7: this.carrierCompCd7,
                });
              });
              this.inputList = list;
              this.inputListOld = JSON.parse(JSON.stringify(list));
              this.saveViewData(this.formatNo);
              this.tmpViewData = JSON.parse(JSON.stringify(this.viewData));
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            console.error("getFormat() Resolve", resolve);

            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * ラベルコードに一致する要素を取得
     */
    getResponse(labelCode) {
      if (this.inputList == null || this.inputList.length === 0) {
        return;
      }
      let obj = this.inputList.filter((value) => {
        return value.subInfomationCd == labelCode;
      });
      return obj.length === 0 ? "" : obj[0].subInfomationComment;
    },
    /**
     * フォーマット別に必要な画面情報を保存する
     */
    saveViewData(formatNo) {
      switch (formatNo) {
        case "SA001":
          this.saveSA001();
          break;
        case "SA002":
          this.saveSA002();
          break;
        case "SE001":
          this.saveSE001();
          break;
        case "FU001":
          this.saveFU001();
          break;
      }
    },
    /**
     * フォーマット別にAPIのパラメータを更新する
     */
    updatePostApiParameter(formatNo) {
      switch (formatNo) {
        case "SA001":
          // 冷凍・冷蔵選択
          this.updateApiParameter("L027");
          this.updateApiParameter("L028");
          this.updateApiParameter("L029");
          // 保険金額
          this.updateApiParameter("L035");
          break;
        case "SA002":
          // 保険金額
          this.updateApiParameter("L035");
          break;
        case "SE001":
          // 保険金額
          this.updateApiParameter("L065");
          break;
        case "FU001":
          // 保険金額
          this.updateApiParameter("L065");
          break;
      }
    },

    /**
     * APIパラメータを更新する
     */
    updateApiParameter(labelCode) {
      if (this.inputList == null || this.inputList.length === 0) {
        return;
      }
      let obj = this.inputList.filter((value) => {
        return value.subInfomationCd == labelCode;
      });
      if (obj.length === 0) {
        return;
      }
      obj = obj[0];
      let updateObj;
      switch (labelCode) {
        case "L027":
          updateObj =
            !this.viewData.isRefrigeratedSelection && !this.viewData.isFrozenSelected
              ? obj.code
              : "";
          break;
        case "L028":
          updateObj = this.viewData.isRefrigeratedSelection ? obj.code : "";
          break;
        case "L029":
          updateObj = this.viewData.isFrozenSelected ? obj.code : "";
          break;
        case "L035":
          updateObj = this.convertAmountToNumber(this.viewData.insuranceAmount);
          break;
        case "L065":
          updateObj = this.convertAmountToNumber(this.viewData.insuranceAmount);
          break;
        default:
          return;
      }
      this.inputList[this.inputList.indexOf(obj)].subInfomationComment = updateObj;
    },

    /**
     * 佐川急便用SA001フォーマットの場合の画面情報を保存する
     */
    saveSA001() {
      let isFrozenSelected = this.getResponse("L029");
      let isRefrigeratedSelection = this.getResponse("L028");
      this.viewData = {
        isFrozenSelected: isFrozenSelected != null && isFrozenSelected != "",
        isRefrigeratedSelection: isRefrigeratedSelection != null && isRefrigeratedSelection != "",
        insuranceAmount: this.parseAmountFormat(this.getResponse("L035"), true),
      };
    },
    /**
     * 佐川急便用SA002フォーマットの場合の画面情報を保存する
     */
    saveSA002() {
      this.viewData = {
        insuranceAmount: this.parseAmountFormat(this.getResponse("L035"), true),
      };
    },
    /**
     * 西濃運輸用SE001フォーマットの場合の画面情報を保存する
     */
    saveSE001() {
      this.viewData = {
        insuranceAmount: this.parseAmountFormat(this.getResponse("L065"), true),
      };
    },
    /**
     * 福山通運用FA001フォーマットの場合の画面情報を保存する
     */
    saveFU001() {
      this.viewData = {
        insuranceAmount: this.parseAmountFormat(this.getResponse("L065"), true),
      };
    },
    reload() {},
    /**
     * 閉じる処理
     */
    back() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_SHP_005,
        params: { searchConditions: this.routeSlipSearchConditions },
      });
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * カンマ付きの数字に変換する
     */
    parseAmountFormat(value, isAmount) {
      if (!value) {
        return value;
      }
      let input = String(value);
      let isDecimal = 1 < input.split(".").length;
      let decimal;
      if (isDecimal) {
        decimal = input.split(".")[1];
      }
      input = input.split(".")[0];
      input = commonUtil.zen2han(input);
      let enInclude = input.includes("¥", 0);
      if (enInclude) {
        input = input.slice(1);
      }
      if (isNaN(Number(input.replaceAll(",", "")))) {
        return value;
      }
      input = String(Number(input.replaceAll(",", "")));
      let delimiter = ",";
      let len = input.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = input.slice(0, index);
          let behindTxt = input.slice(index);
          input = previousTxt + delimiter + behindTxt;
        }
      }
      if (isAmount) {
        let price = this.strIns(input, 0, "¥");
        if (isDecimal && decimal != 0) {
          price = this.strIns(price, price.length, "." + decimal);
        }
        return price;
      } else {
        return input;
      }
    },
    /**
     * 指定の位置に文字列を挿入する
     */
    strIns(str, idx, val) {
      return str.slice(0, idx) + val + str.slice(idx);
    },
    /**
     * 登録処理
     */
    registration() {
      let isSuccess = false;
      // this.endEvent("btn.btn_insert");
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_010;

      this.updatePostApiParameter(this.formatNo);
      body.reqIdv.routeSlip = this.inputList;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.ROUTE_SLIP_DETAILS_REGIT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("registration() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常の場合
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-SHP-010_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              isSuccess = true;
              resolve(response);
            } else {
              // エラーの場合
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            if (isSuccess) {
              this.endEvent();
              this.getFormat();
            }
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // イベント終了ダイアログ表示
    endEvent() {
      this.infoDialog.message = messsageUtil.getMessage("P-SHP-010_001_C");
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.shpAddFormatFlg = true;
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      switch (button) {
        case "btn_insert":
          if (!this.isDataUpdated()) {
            return;
          }
          isValidationRequired = true;
          message = "P-RCV-001_003_C";
          action = this.registration;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = "P-COM-001_004_W";
          action = this.back;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        let isValid = this.$refs.form.validate();
        if (!isValid) {
          this.scrollToErrorRow();
          return;
        }
      }
      // let isValid = !isValidationRequired ? true : this.$refs.form.validate();
      // if (!isValid) {
      //   this.scrollToErrorRow();
      //   return;
      // } else {
      //   if (button === "btn_insert" && this.inputList.length == this.inputListOld.length) {
      //     if (!this.checkChange()) {
      //       this.infoDialog.message = messsageUtil.getMessage("P-BIL-005_001_E");
      //       this.infoDialog.title = appConfig.DIALOG.title;
      //       this.infoDialog.isOpen = true;
      //       this.infoDialog.screenFlag = true;
      //       this.infoDialog.bilActualFlg = false;
      //       return;
      //     }
      //   }
      //   this.ConfirmDialog.message = message;
      //   this.ConfirmDialog.okAction = action;
      //   this.ConfirmDialog.isOpen = true;
      //   this.ConfirmDialog.screenFlag = true;
      //   this.ConfirmDialog.changeFlag = false;
      // }
      this.ConfirmDialog.message = messsageUtil.getMessage(message);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },
    // 変更チェック
    // checkChange() {
    //   if (this.inputList.length != this.inputListOld.length) {
    //     return false;
    //   }
    //   for (var i = 0; i < this.inputListOld.length; i++) {
    //     if (
    //       this.insuranceAmount != this.inputListOld[i].insuranceAmount ||
    //       this.isFrozenSelected != this.inputListOld[i].isFrozenSelected ||
    //       this.isRefrigeratedSelection != this.inputListOld[i].isRefrigeratedSelection
    //     ) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    /**
     * 冷凍・冷蔵選択
     */
    checkFrozenRefrigeratedSelection(target) {
      switch (target) {
        case "frozen":
          this.viewData.isRefrigeratedSelection = false;
          break;
        case "refrigerated":
          this.viewData.isFrozenSelected = false;
          break;
      }
    },
    /**
     * 表示項目の制御をする
     */
    controllView(viewItemName) {
      let obj = this.viewItemMap.get(this.formatNo);
      if (obj ?? false) {
        return obj.includes(viewItemName);
      }
      return false;
    },
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 金額を整数に変換する
     */
    convertAmountToNumber(value) {
      if (value === void 0 || value === null || value === "") {
        return value;
      }
      value = String(value);
      let enInclude = value.includes("¥", 0);
      if (enInclude) {
        value = value.slice(1);
      }
      return value.replaceAll(",", "");
    },
  },
  computed: {},
  watch: {
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dateToDeliverCal(val) {
      this.dateToDeliver = this.formatDate(val);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-row {
  pointer-events: none;
}

.search-autocomplete-product {
  width: 24rem;
}

.calender-component {
  margin: 16px 0 0 0;
  height: 3rem;
}
.detail {
  display: flex;
  align-items: center;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: #dcdcdc;
  background-color: #d3d3d3;
}
.detail-top-element {
  display: flex;
  align-items: center;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #dcdcdc;
  background-color: #d3d3d3;
}
.detail-right-element {
  display: flex;
  align-items: center;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #dcdcdc;
}
.detail-rightTop-element {
  display: flex;
  align-items: center;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #dcdcdc;
}
.inner-checkBox {
  height: 100%;
}
</style>
